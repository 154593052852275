import React, { useState, useRef, useEffect } from "react"
import styles from "./styles.module.scss"
import "./carousel.css" // copy css from module for customization

const TestimonialVideo = ({ isVisible }) => {
  const [videoLoaded, setVideoLoaded] = useState(false)
  const video = useRef()

  useEffect(() => {
    video.current.load()
    setVideoLoaded(true)
  }, [])

  useEffect(() => {
    if (!isVisible && video.current) {
      video.current.pause()
    }
  }, [isVisible])

  useEffect(() => {
    if (videoLoaded) {
      if (isVisible) {
        video.current.play()
      } else {
        video.current.pause()
      }
    }
  }, [videoLoaded, video.current, isVisible])

  return (
    <div className={styles.carouselVideoContainer}>
      <h2 className={styles.carouselVideoTitle}> Teddy's Story</h2>
      <div className={styles.carouselVideoWrapper}>
        <video
          ref={video}
          className={styles.carouselVideo}
          muted="muted"
          playsInline
          controls
        >
          <source src="/neighborshare_video.mp4" type="video/mp4" />
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src="/caption.en_US.vtt"
            default
          />
        </video>
      </div>
    </div>
  )
}

export default TestimonialVideo
